import { client } from '../lib/client';

const verifyUserToken = (token) => {
  const body = { token };
  return client.post('/verify-user-token', body).then((resp) => {
    const { data } = resp;
    return {
      email: data.email,
      jwtToken: data.jwtToken,
      businessAccountId: data.profile,
      userId: data.id,
      token: data.token,
    };
  });
};

export default verifyUserToken;
