import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'react-quill/dist/quill.snow.css';
import 'nprogress/nprogress.css';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import StyledEngineProvider from '@material-ui/core/StyledEngineProvider';
import App from './App';
import { AuthProvider } from './contexts/JWTContext';
import { SettingsProvider } from './contexts/SettingsContext';
import store from './store';
import { ApolloProvider } from '@apollo/client';
import gqlClient from './lib/gqlClient';
import ConfirmationModalContextProvider from './contexts/ConfirmationModalContext';

ReactDOM.render(
  <StrictMode>
    <ApolloProvider client={gqlClient}>
      <ConfirmationModalContextProvider>
        <HelmetProvider>
          <ReduxProvider store={store}>
            <StyledEngineProvider injectFirst>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <SettingsProvider>
                  <BrowserRouter>
                    <AuthProvider>
                      <App />
                    </AuthProvider>
                  </BrowserRouter>
                </SettingsProvider>
              </LocalizationProvider>
            </StyledEngineProvider>
          </ReduxProvider>
        </HelmetProvider>
      </ConfirmationModalContextProvider>
    </ApolloProvider>
  </StrictMode>,
  document.getElementById('root')
);
