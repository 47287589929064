import { client } from '../lib/client';

const getUser = (userId, refreshUser = false) => {
  return client.get(`/users/${userId}?refreshUser=${refreshUser}`).then((resp) => {
    const { data } = resp;
    return data;
  });
};

export default getUser;
