import { createContext, useCallback, useMemo, useState } from 'react';

const initialValue = {
  title: '',
  content: '',
  onConfirm: Promise.resolve,
  open: false,
  cancelText: 'Cancel',
  okText: 'OK',
  loading: false,
  openConfirmation: () => {},
  closeConfirmation: () => {},
  setLoading: () => {},
};

export const ConfirmationContext = createContext(initialValue);

const ConfirmationModalContextProvider = (props) => {
  const [contextValue, setContextValue] = useState(initialValue);

  const openConfirmation = useCallback(
    ({ title = '', content = '', cancelText = 'Cancel', okText = 'Ok', onConfirm }) => {
      setContextValue({
        ...contextValue,
        title,
        content,
        onConfirm,
        cancelText,
        okText,
        loading: false,
        open: true,
      });
    },
    [setContextValue, contextValue]
  );

  const closeConfirmation = useCallback(() => {
    setContextValue(() => {
      return {
        ...contextValue,
        loading: true,
        open: false,
      };
    });
  }, [setContextValue, contextValue]);

  const setLoading = useCallback(
    (loading) => {
      setContextValue(() => ({
        ...contextValue,
        loading,
      }));
    },
    [contextValue, setContextValue]
  );

  const value = useMemo(() => {
    return {
      ...contextValue,
      openConfirmation,
      closeConfirmation,
      setLoading,
    };
  }, [contextValue]);

  return (
    <ConfirmationContext.Provider value={value}>{props.children}</ConfirmationContext.Provider>
  );
};

export default ConfirmationModalContextProvider;
