import { experimentalStyled } from '@material-ui/core/styles';
import logoSrc from '../icons/logo_white.png';

const LogoRoot = experimentalStyled('div')`
img {
  height: 40px;
}
`;

const Logo = (props) => (
  <LogoRoot
    height="52"
    version="1.1"
    viewBox="0 0 52 52"
    width="52"
    {...props}
  >

    <img
      src={logoSrc}
      alt="logo"
    />
  </LogoRoot>
);

export default Logo;
