import { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { authApi } from '../__fakeApi__/authApi';
import verifyUserToken from '../api/verify-user-token';
import loginApi from '../api/login';
import gqlClient, { setGraphqlAuthHeaders } from '../lib/gqlClient';
import { setApiClientAuthHeader } from '../lib/client';
import getUser from '../api/getUser';

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  accessToken: null,
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user, accessToken } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
      accessToken,
    };
  },
  LOGIN: (state, action) => {
    const { user, accessToken } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
      accessToken,
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
    accessToken: null,
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },

  UPDATE_USER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      user,
      accessToken: user.jwtToken,
    };
  },
};

// eslint-disable-next-line no-constant-condition
const reducer = (state, action) => ([action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
  ...initialState,
  platform: 'JWT',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  verify: () => Promise.resolve(),
  updateUser: () => Promise.resolve(),
});

export const AuthProvider = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  const handleSetJwtToken = (accessToken) => {
    localStorage.setItem('accessToken', accessToken);
    setGraphqlAuthHeaders(accessToken);
    setApiClientAuthHeader(accessToken);
  };

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');

        if (accessToken) {
          const tokenData = await verifyUserToken(accessToken);
          handleSetJwtToken(accessToken);
          const user = await getUser(tokenData.userId);
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user,
              accessToken,
            },
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null,
              accessToken: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null,
            accessToken: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const verify = async (token) => {
    const user = await verifyUserToken(token);
    const { jwtToken } = user;
    handleSetJwtToken(jwtToken);
    dispatch({
      type: 'INITIALIZE',
      payload: {
        isAuthenticated: true,
        user,
      },
    });
  };

  const updateUser = async () => {
    const user = await getUser(state.user.id, true);
    handleSetJwtToken(user.jwtToken);
    dispatch({
      type: 'UPDATE_USER',
      payload: {
        user,
      },
    });
  };

  const login = async (email, password) => {
    const loginUserData = await loginApi(email, password);
    const { jwtToken, userId } = loginUserData;
    setApiClientAuthHeader(jwtToken);
    const user = await getUser(userId);
    handleSetJwtToken(jwtToken);
    dispatch({
      type: 'LOGIN',
      payload: {
        user,
        accessToken: jwtToken,
      },
    });
  };

  const logout = async () => {
    localStorage.removeItem('accessToken');
    gqlClient.resetStore();
    dispatch({ type: 'LOGOUT' });
  };

  const register = async (email, name, password) => {
    const accessToken = await authApi.register({ email, name, password });
    const user = await authApi.me(accessToken);

    localStorage.setItem('accessToken', accessToken);

    dispatch({
      type: 'REGISTER',
      payload: {
        user,
      },
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        platform: 'JWT',
        login,
        logout,
        register,
        verify,
        updateUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthContext;
