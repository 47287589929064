import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
} from '@apollo/client';
import { baseURL } from './client';
import { setContext } from '@apollo/client/link/context';

const httpLink = createHttpLink({
  uri: `${baseURL}/graphql`,
});

const client = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache({
    addTypename: false
  })
});

export function setGraphqlAuthHeaders(token) {
  const localToken = localStorage.getItem('accessToken');

  const headerToken = token || localToken;

  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        Authorization: headerToken ? `Bearer ${headerToken}` : null,
      },
    };
  });

  client.setLink(authLink.concat(httpLink));
}

export default client;
