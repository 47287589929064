import { experimentalStyled } from '@material-ui/core/styles';
import manualSrc from '../icons/manual.png';

const ManualRoot = experimentalStyled('div')`
img {
  height: 40px;
}
`;

const ManualDark = (props) => (
  <ManualRoot
    height="48"
    version="1.1"
    viewBox="0 0 48 48"
    width="48"
    {...props}
  >

    <img
      src={manualSrc}
      alt="user manual"
    />
  </ManualRoot>
);

export default ManualDark;
