import { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Avatar, Box, Divider, Drawer, Link, Typography } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useAuth from '../../hooks/useAuth';
import BriefcaseIcon from '../../icons/Briefcase';

import VerifiedUser from '@material-ui/icons/VerifiedUser';
import StorefrontIcon from '@material-ui/icons/Storefront';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import RoomService from '@material-ui/icons/RoomService';
import Logo from '../LogoDark';
import Manual from '../ManualDark';
import NavSection from '../NavSection';
import Scrollbar from '../Scrollbar';

// const sections = [
//   // {
//   //   title: 'Manage My Business',
//   //   items: [
//   //     {
//   //       title: 'Customers',
//   //       path: '/dashboard/customers',
//   //       icon: <BriefcaseIcon fontSize="small" />,
//   //       children: [
//   //         {
//   //           title: 'List',
//   //           path: '/dashboard/customers'
//   //         },
//   //         {
//   //           title: 'Details',
//   //           path: '/dashboard/customers/1'
//   //         },
//   //         {
//   //           title: 'Edit',
//   //           path: '/dashboard/customers/1/edit'
//   //         }
//   //       ]
//   //     },
//   //     {
//   //       title: 'Products',
//   //       path: '/dashboard/products',
//   //       icon: <ShoppingCartIcon fontSize="small" />,
//   //       children: [
//   //         {
//   //           title: 'List',
//   //           path: '/dashboard/products'
//   //         },
//   //         {
//   //           title: 'Create',
//   //           path: '/dashboard/products/new'
//   //         }
//   //       ]
//   //     },
//   //     {
//   //       title: 'Orders',
//   //       icon: <FolderOpenIcon fontSize="small" />,
//   //       path: '/dashboard/orders',
//   //       children: [
//   //         {
//   //           title: 'List',
//   //           path: '/dashboard/orders'
//   //         },
//   //         {
//   //           title: 'Details',
//   //           path: '/dashboard/orders/1'
//   //         }
//   //       ]
//   //     },
//   //     {
//   //       title: 'Invoices',
//   //       path: '/dashboard/invoices',
//   //       icon: <ReceiptIcon fontSize="small" />,
//   //       children: [
//   //         {
//   //           title: 'List',
//   //           path: '/dashboard/invoices'
//   //         },
//   //         {
//   //           title: 'Details',
//   //           path: '/dashboard/invoices/1'
//   //         }
//   //       ]
//   //     }
//   //   ]
//   // },
//   {
//     title: 'Manage My Business',
//     items: [
//       {
//         title: 'Business Account',
//         path: '/dashboard/business-account',
//         icon: <BriefcaseIcon fontSize="small" />,
//       },
//       {
//         title: 'Brands',
//         path: '/dashboard/brands',
//         icon: <VerifiedUser fontSize="small" />,
//       },
//       {
//         title: 'Locations',
//         path: '/dashboard/locations',
//         icon: <StorefrontIcon fontSize="small" />,
//       },
//       {
//         title: 'Products',
//         path: '/dashboard/products',
//         icon: <ShoppingBasketIcon fontSize="small" />,
//       },
//       {
//         title: 'Services',
//         path: '/dashboard/services',
//         icon: <RoomService fontSize="small" />,
//       },
//     ],
//   },
// ];

const getSections = (user) => {
  const sections = [
    {
      title: 'Manage My Business',
      items: [
        {
          title: 'Business Account',
          path: '/dashboard/business-account',
          icon: <BriefcaseIcon fontSize="small" />,
        },
      ],
    },
  ];
  if (user.has_profile && user.businessAccountId !== '0') {
    sections[0].items.push(
      {
        title: 'Brands',
        path: '/dashboard/brands',
        icon: <VerifiedUser fontSize="small" />,
      },
      {
        title: 'Locations',
        path: '/dashboard/locations',
        icon: <StorefrontIcon fontSize="small" />,
      },
      {
        title: 'Products',
        path: '/dashboard/products',
        icon: <ShoppingBasketIcon fontSize="small" />,
      },
      {
        title: 'Services',
        path: '/dashboard/services',
        icon: <RoomService fontSize="small" />,
      }
    );
  }

  return sections;
};

const DashboardSidebar = (props) => {
  const { onMobileClose, openMobile } = props;
  const location = useLocation();
  const { user } = useAuth();
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));
  const sections = getSections(user);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <Box
          sx={{
            display: {
              lg: 'none',
              xs: 'flex',
            },
            justifyContent: 'left',
            p: 2,
          }}
        >
          <Link href="https://damamaps.com/" target="_blank">
            <Logo
              sx={{
                height: 40,
                width: 40,
              }}
            />
          </Link>
        </Box>
        <Box sx={{ p: 1 }}>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              overflow: 'hidden',
              p: 1,
            }}
          >
            <RouterLink to="/static/manual.pdf" target="blank">
              <Manual
                sx={{
                  cursor: 'pointer',
                  height: 40,
                  width: 40,
                }}
              />
            </RouterLink>
            <Box sx={{ ml: 2 }}>
              <RouterLink to="/static/manual.pdf" target="blank">
                <Typography color="textFirst" variant="body">
                  User Manual
                </Typography>
              </RouterLink>
            </Box>
          </Box>
        </Box>
        <Box sx={{ p: 2 }}>
          <Box
            sx={{
              alignItems: 'center',
              backgroundColor: 'background.default',
              borderRadius: 1,
              display: 'flex',
              overflow: 'hidden',
              p: 2,
            }}
          >
            <RouterLink to="/dashboard/account">
              <Avatar
                src={user.avatar}
                sx={{
                  cursor: 'pointer',
                  height: 48,
                  width: 48,
                }}
              />
            </RouterLink>
            <Box sx={{ ml: 2 }}>
              <Typography color="textPrimary" variant="subtitle2">
                {user.name}
              </Typography>
              <Typography color="textSecondary" variant="body2">
                Your plan:{' '}
                <Link color="primary" component={RouterLink} to="/pricing">
                  {user.plan}
                </Link>
              </Typography>
            </Box>
          </Box>
        </Box>
        <Divider />
        <Box sx={{ p: 2 }}>
          {sections.map((section) => (
            <NavSection
              key={section.title}
              pathname={location.pathname}
              sx={{
                '& + &': {
                  mt: 3,
                },
              }}
              {...section}
            />
          ))}
        </Box>
      </Scrollbar>
    </Box>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: 'background.paper',
            height: 'calc(100% - 64px) !important',
            top: '64px !Important',
            width: 280,
          },
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onMobileClose}
      open={openMobile}
      PaperProps={{
        sx: {
          backgroundColor: 'background.paper',
          width: 280,
        },
      }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default DashboardSidebar;
