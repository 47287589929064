import React, { useContext } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { CircularProgress, Box } from '@material-ui/core';
import { ConfirmationContext } from '../contexts/ConfirmationModalContext';

const Confirmation = () => {
  const {
    open,
    title,
    content,
    okText,
    cancelText,
    loading,
    closeConfirmation,
    setLoading,
    onConfirm,
  } = useContext(ConfirmationContext);

  const handleConfirm = async () => {
    setLoading(true);
    await onConfirm();
    closeConfirmation();
  };

  return (
    <Dialog open={open} onClose={closeConfirmation}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {loading ? (
          <Box minWidth={200}>
            <CircularProgress sx={{ mx: 'auto', display: 'block' }} />
          </Box>
        ) : (
          <DialogContentText>{content}</DialogContentText>
        )}
      </DialogContent>
      {!loading && (
        <DialogActions>
          <Button onClick={closeConfirmation}>{cancelText || 'Cancel'}</Button>
          <Button onClick={handleConfirm}>{okText || 'OK'}</Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default Confirmation;
