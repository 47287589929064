import { client } from '../lib/client';

const login = (email, pwd) => {
  const body = { email, pwd };
  return client.post('/login', body).then((resp) => {
    const { data } = resp;
    return {
      email: data.email,
      jwtToken: data.jwtToken,
      businessAccountId: data.profile,
      userId: data.userid,
      token: data.token,
    };
  });
};

export default login;
