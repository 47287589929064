export const THEMES = {
  LIGHT: 'LIGHT',
  DARK: 'DARK',
  NATURE: 'NATURE',
};

export const SOCIAL_PLATFORMS = {
  Facebook: 'Facebook',
  Instagram: 'Instagram',
  X: 'Twitter',
  Linkedin: 'Linkedin',
  YouTube: 'YouTube',
  'Apple Podcasts': 'ApplePodcasts',
  Spotify: 'Spotify',
  'Google Podcasts': 'GooglePodcasts',
  WeChat: 'WeChat',
  Line: 'Line',
  Telegram: 'Telegram',
  Tiktok: 'Tiktok',
  DouYin: 'DouYin',
  RedBook: 'RedBook',
  Others: 'Others',
};

export const LICENSE_TYPE = {
  ADULT_USE_CULTIVTION: 'Adult-Use Cultivation ',
  ADULT_USE_MFG: 'Adult-Use Mfg.',
  ADULT_USE_NSF: 'Adult-Use Nonstorefront',
  ADULT_USE_RETAILER: 'Adult-Use Retail',
  DISTRIBUTOR: 'Distributor',
  EVENT: 'Event',
  MEDICAL_CULTIVATION: 'Medical Cultivation',
  MEDICAL_MFG: 'Medical Mfg',
  MEDICAL_NSG: 'Medical Nonstorefront',
  MEDICAL_RETAILER: 'Medical Retail',
  MICRO_BUSINESS: 'Microbusiness',
  TESTING_LAB: 'Testing Lab',
  OTHERS: 'Others',
};

export const BRAND_BUSINESS_TYPE = {
  LOCATION: 'LOCATION',
  PRODUCT: 'PRODUCT',
  SERVICE: 'SERVICE',
  OTHERS: 'OTHERS',
};

export const LOCATION_BUSINESS_TYPE = {
  FRIENDLY_420: '420 Friendly',
  DISPENSARY: 'Dispensary  (THC / THC&CBD)',
  CBD_STORE: 'CBD Store (CBD only)',
  CLINICS: 'Clinics',
  CULTIVATION: 'Cultivation',
  Logistics: 'Logistics',
  GROW_SHOP: 'Grow Shop',
  HEAD_SHOP: 'Head Shop',
  LABS: 'Labs',
  LEGAL: 'Legal',
  LIFESTYLE: 'Lifestyle (Spa, Restaurant)',
  MUNCHIES: 'Munchies',
  SCHOOLS: 'Schools',
  SERVICE_OFFICE: 'Service Office',
  OTHERS: 'Others',
};

export const SERVICE_CATEGORY = {
  Accounting: 'Accounting',
  Consulting: 'Consulting',
  Metaverse: 'METAVERSE (NFT/ E-SPORT / MIXED REALITY / CRYPTOCURRENCY)',
  Finance: 'Finance (Banking / Payment Processing / Capital Loans)',
  Insurance: 'Insurance',
  Label_Packaging: 'Label & Packaging',
  Legal: 'Legal',
  Lifestyle: 'Lifestyle (Spa / Restaurant / 420 Friendly / Munchies / Lodging)',
  Logistics: 'Logistics (Delivery/Security/Transportation)',
  Marketing: 'Marketing',
  Networking: 'Networking (Events)',
  RND: 'R&D',
  Real_Estate: 'Real Estate',
  Technology: 'Technology (Software / SaaS / PaaS )',
  Staffing_Training: 'Staffing & Training ',
  Testing_Lab: 'Testing lab',
  TOURS: 'Tours',
};

export const PRODUCT_AVAILABILITY = {
  pick: 'Pick up',
  delivery: 'Delivery',
  pickup_delivery: 'Pick up & Delivery',
};

export const MAPPING_DATA = {
  businessType: {
    1: 'Storefront Location (CBD/THC/Retail Dispensary)',
    2: 'Clinic/Doctor',
    3: 'Delivery Only Service',
    4: 'Hemp Derived CBD Brand',
    5: 'Hardware (Glass, Vape, Accessories)',
    6: 'THC Infused Brand (Producer/Processor)',
    7: 'Grow Supply',
    8: 'B2B Service',
    9: 'Lab/ Testing Facility',
    10: 'Other',
  },
  productType: {
    indica: 'Indica',
    sativa: 'Sativa',
    hybrid: 'Hybrid',
  },
  productCategoryGroup1: {
    Apparel: 'Apparel',
    Cannabis: 'Cannabis',
    Concentrates: 'Concentrates',
    Dabbing: 'Dabbing',
    Edibles: 'Edibles',
    Other: 'Other',
    Services: 'Services',
    Smoking: 'Smoking',
    Storage: 'Storage',
    Topicals: 'Topicals',
    Vaping: 'Vaping',
    'Books & Games': 'Books & Games',
    Growing: 'Growing',
    'Hemp CBD': 'Hemp CBD',
    Pets: 'Pets',
    Tourism: 'Tourism',
  },
  productCategory1: {
    apparel_hats: {
      prodCategory: 'Hats',
      productCategoryGroup: 'Apparel',
      productCategoryId: 'apparel_hats',
    },
    apparel_hoodies: {
      prodCategory: 'Hoodies ',
      productCategoryGroup: 'Apparel',
      productCategoryId: 'apparel_hoodies',
    },
    apparel_shirts: {
      prodCategory: 'Shirts ',
      productCategoryGroup: 'Apparel',
      productCategoryId: 'apparel_shirts',
    },
    apparel_others: {
      prodCategory: 'Other apparel ',
      productCategoryGroup: 'Apparel',
      productCategoryId: 'apparel_others',
    },
    cannabis_clones: {
      prodCategory: 'Clones',
      productCategoryGroup: 'Cannabis',
      productCategoryId: 'cannabis_clones',
    },
    cannabis_flower: {
      prodCategory: 'Flower',
      productCategoryGroup: 'Cannabis',
      productCategoryId: 'cannabis_flower',
    },
    'cannabis_pre-rolls': {
      prodCategory: 'Pre-rolls',
      productCategoryGroup: 'Cannabis',
      productCategoryId: 'cannabis_pre-rolls',
    },
    cannabis_seeds: {
      prodCategory: 'Seeds',
      productCategoryGroup: 'Cannabis',
      productCategoryId: 'cannabis_seeds',
    },
    concentrates_cartridges: {
      prodCategory: 'Cartridges',
      productCategoryGroup: 'Concentrates',
      productCategoryId: 'concentrates_cartridges',
    },
    concentrates_ingestible: {
      prodCategory: 'Ingestible',
      productCategoryGroup: 'Concentrates',
      productCategoryId: 'concentrates_ingestible',
    },
    concentrates_solvent: {
      prodCategory: 'Solvent',
      productCategoryGroup: 'Concentrates',
      productCategoryId: 'concentrates_solvent',
    },
    concentrates_solventless: {
      prodCategory: 'Solventless',
      productCategoryGroup: 'Concentrates',
      productCategoryId: 'concentrates_solventless',
    },
    concentrates_terpenes: {
      prodCategory: 'Terpenes',
      productCategoryGroup: 'Concentrates',
      productCategoryId: 'concentrates_terpenes',
    },
    'dabbing_dab-oil-rigs': {
      prodCategory: 'Dab & oil rigs',
      productCategoryGroup: 'Dabbing',
      productCategoryId: 'dabbing_dab-oil-rigs',
    },
    'dabbing_nails-attachments': {
      prodCategory: 'Nails & attachments',
      productCategoryGroup: 'Dabbing',
      productCategoryId: 'dabbing_nails-attachments',
    },
    'dabbing_tools-accessories': {
      prodCategory: 'Tools & accessories',
      productCategoryGroup: 'Dabbing',
      productCategoryId: 'dabbing_tools-accessories',
    },
    edibles_beverages: {
      prodCategory: 'Beverages',
      productCategoryGroup: 'Edibles',
      productCategoryId: 'edibles_beverages',
    },
    edibles_breakfast: {
      prodCategory: 'Breakfast',
      productCategoryGroup: 'Edibles',
      productCategoryId: 'edibles_breakfast',
    },
    edibles_brownies: {
      prodCategory: 'Brownies',
      productCategoryGroup: 'Edibles',
      productCategoryId: 'edibles_brownies',
    },
    edibles_candy: {
      prodCategory: 'Candy',
      productCategoryGroup: 'Edibles',
      productCategoryId: 'edibles_candy',
    },
    edibles_capsules: {
      prodCategory: 'Capsules',
      productCategoryGroup: 'Edibles',
      productCategoryId: 'edibles_capsules',
    },
    edibles_chocolates: {
      prodCategory: 'Chocolates',
      productCategoryGroup: 'Edibles',
      productCategoryId: 'edibles_chocolates',
    },
    edibles_condiments: {
      prodCategory: 'Condiments',
      productCategoryGroup: 'Edibles',
      productCategoryId: 'edibles_condiments',
    },
    edibles_cookies: {
      prodCategory: 'Cookies',
      productCategoryGroup: 'Edibles',
      productCategoryId: 'edibles_cookies',
    },
    edibles_cooking: {
      prodCategory: 'Cooking',
      productCategoryGroup: 'Edibles',
      productCategoryId: 'edibles_cooking',
    },
    edibles_frozen: {
      prodCategory: 'Frozen',
      productCategoryGroup: 'Edibles',
      productCategoryId: 'edibles_frozen',
    },
    'edibles_snack-foods': {
      prodCategory: 'Snack foods',
      productCategoryGroup: 'Edibles',
      productCategoryId: 'edibles_snack-foods',
    },
    'edibles_tinctures-sublingual': {
      prodCategory: 'Tinctures & sublingual',
      productCategoryGroup: 'Edibles',
      productCategoryId: 'edibles_tinctures-sublingual',
    },
    other_miscellaneous: {
      prodCategory: 'Miscellaneous',
      productCategoryGroup: 'Other',
      productCategoryId: 'other_miscellaneous',
    },
    'services_analytics-testing': {
      prodCategory: 'Analytics & testing',
      productCategoryGroup: 'Services',
      productCategoryId: 'services_analytics-testing',
    },
    smoking_ashtrays: {
      prodCategory: 'Ashtrays',
      productCategoryGroup: 'Smoking',
      productCategoryId: 'smoking_ashtrays',
    },
    'smoking_bongs-waterpipes': {
      prodCategory: 'Bongs & waterpipes',
      productCategoryGroup: 'Smoking',
      productCategoryId: 'smoking_bongs-waterpipes',
    },
    'smoking_bowl-pieces': {
      prodCategory: 'Bowl pieces',
      productCategoryGroup: 'Smoking',
      productCategoryId: 'smoking_bowl-pieces',
    },
    smoking_bubblers: {
      prodCategory: 'Bubblers',
      productCategoryGroup: 'Smoking',
      productCategoryId: 'smoking_bubblers',
    },
    smoking_grinders: {
      prodCategory: 'Grinders',
      productCategoryGroup: 'Smoking',
      productCategoryId: 'smoking_grinders',
    },
    smoking_lighters: {
      prodCategory: 'Lighters',
      productCategoryGroup: 'Smoking',
      productCategoryId: 'smoking_lighters',
    },
    smoking_pipes: {
      prodCategory: 'Pipes',
      productCategoryGroup: 'Smoking',
      productCategoryId: 'smoking_pipes',
    },
    'smoking_rolling-machines': {
      prodCategory: 'Rolling machines',
      productCategoryGroup: 'Smoking',
      productCategoryId: 'smoking_rolling-machines',
    },
    'smoking_rolling-papers': {
      prodCategory: 'Rolling papers',
      productCategoryGroup: 'Smoking',
      productCategoryId: 'smoking_rolling-papers',
    },
    'smoking_rolling-trays': {
      prodCategory: 'Rolling trays',
      productCategoryGroup: 'Smoking',
      productCategoryId: 'smoking_rolling-trays',
    },
    smoking_scales: {
      prodCategory: 'Scales',
      productCategoryGroup: 'Smoking',
      productCategoryId: 'smoking_scales',
    },
    'smoking_smoking-accessories': {
      prodCategory: 'Smoking accessories',
      productCategoryGroup: 'Smoking',
      productCategoryId: 'smoking_smoking-accessories',
    },
    'storage_bong-pipe-storage': {
      prodCategory: 'Bong & pipe storage',
      productCategoryGroup: 'Storage',
      productCategoryId: 'storage_bong-pipe-storage',
    },
    'storage_bongs-concentrate-storage': {
      prodCategory: 'Concentrate storage',
      productCategoryGroup: 'Storage',
      productCategoryId: 'storage_bongs-concentrate-storage',
    },
    'storage_bowl-flower-storage': {
      prodCategory: 'Flower storage',
      productCategoryGroup: 'Storage',
      productCategoryId: 'storage_bowl-flower-storage',
    },
    topicals_balms: {
      prodCategory: 'Balms',
      productCategoryGroup: 'Topicals',
      productCategoryId: 'topicals_balms',
    },
    topicals_lotions: {
      prodCategory: 'Lotions',
      productCategoryGroup: 'Topicals',
      productCategoryId: 'topicals_lotions',
    },
    'topicals_lubricants-oils': {
      prodCategory: 'Lubricants & oils',
      productCategoryGroup: 'Topicals',
      productCategoryId: 'topicals_lubricants-oils',
    },
    topicals_sprays: {
      prodCategory: 'Sprays',
      productCategoryGroup: 'Topicals',
      productCategoryId: 'topicals_sprays',
    },
    'topicals_transdermal-patches': {
      prodCategory: 'Transdermal patches',
      productCategoryGroup: 'Topicals',
      productCategoryId: 'topicals_transdermal-patches',
    },
    'vaping_batteries-power': {
      prodCategory: 'Batteries & power',
      productCategoryGroup: 'Vaping',
      productCategoryId: 'vaping_batteries-power',
    },
    'vaping_desktop-vaporizers': {
      prodCategory: 'Desktop vaporizers',
      productCategoryGroup: 'Vaping',
      productCategoryId: 'vaping_desktop-vaporizers',
    },
    'vaping_portable-vaporizers': {
      prodCategory: 'Portable vaporizers',
      productCategoryGroup: 'Vaping',
      productCategoryId: 'vaping_portable-vaporizers',
    },
    'vaping_vape-pens': {
      prodCategory: 'Vape pens',
      productCategoryGroup: 'Vaping',
      productCategoryId: 'vaping_vape-pens',
    },
    'vaping_vaporizer-accessories': {
      prodCategory: 'Vaporizer accessories',
      productCategoryGroup: 'Vaping',
      productCategoryId: 'vaping_vaporizer-accessories',
    },
    'books & games_books': {
      prodCategory: 'Books',
      productCategoryGroup: 'Books & Games',
      productCategoryId: 'books & games_books',
    },
    'books & games_games': {
      prodCategory: 'Games',
      productCategoryGroup: 'Books & Games',
      productCategoryId: 'books & games_games',
    },
    'books & games_novelties': {
      prodCategory: 'Novelties',
      productCategoryGroup: 'Books & Games',
      productCategoryId: 'books & games_novelties',
    },
    'books & games_posters-art': {
      prodCategory: 'Posters & Art',
      productCategoryGroup: 'Books & Games',
      productCategoryId: 'books & games_posters-art',
    },
    cannabis_shake: {
      prodCategory: 'Shake',
      productCategoryGroup: 'Cannabis',
      productCategoryId: 'cannabis_shake',
    },
    'growing_climate-controls': {
      prodCategory: 'Climate controls',
      productCategoryGroup: 'Growing',
      productCategoryId: 'growing_climate-controls',
    },
    'growing_grow-lights': {
      prodCategory: 'Grow lights',
      productCategoryGroup: 'Growing',
      productCategoryId: 'growing_grow-lights',
    },
    'growing_grow-media': {
      prodCategory: 'Grow media',
      productCategoryGroup: 'Growing',
      productCategoryId: 'growing_grow-media',
    },
    'growing_grow-tents': {
      prodCategory: 'Grow tents',
      productCategoryGroup: 'Growing',
      productCategoryId: 'growing_grow-tents',
    },
    growing_harvest: {
      prodCategory: 'Harvest',
      productCategoryGroup: 'Growing',
      productCategoryId: 'growing_harvest',
    },
    growing_learning: {
      prodCategory: 'Learning',
      productCategoryGroup: 'Growing',
      productCategoryId: 'growing_learning',
    },
    growing_nutrients: {
      prodCategory: 'Nutrients',
      productCategoryGroup: 'Growing',
      productCategoryId: 'growing_nutrients',
    },
    'growing_other-grow-supplies': {
      prodCategory: 'Other Grow Supplies',
      productCategoryGroup: 'Growing',
      productCategoryId: 'growing_other-grow-supplies',
    },
    'growing_pest-control': {
      prodCategory: 'Pest Control',
      productCategoryGroup: 'Growing',
      productCategoryId: 'growing_pest-control',
    },
    'hemp CBD_bath-body': {
      prodCategory: 'Bath & body',
      productCategoryGroup: 'Hemp CBD',
      productCategoryId: 'hemp CBD_bath-body',
    },
    'hemp CBD_beauty': {
      prodCategory: 'Beauty',
      productCategoryGroup: 'Hemp CBD',
      productCategoryId: 'hemp CBD_beauty',
    },
    'hemp CBD_edibles': {
      prodCategory: 'Hemp CBD edibles',
      productCategoryGroup: 'Hemp CBD',
      productCategoryId: 'hemp CBD_edibles',
    },
    'hemp CBD_oil': {
      prodCategory: 'Hemp CBD oil',
      productCategoryGroup: 'Hemp CBD',
      productCategoryId: 'hemp CBD_oil',
    },
    'hemp CBD_tinctures': {
      prodCategory: 'Hemp CBD tinctures',
      productCategoryGroup: 'Hemp CBD',
      productCategoryId: 'hemp CBD_tinctures',
    },
    'hemp CBD_topicals': {
      prodCategory: 'Hemp CBD topicals',
      productCategoryGroup: 'Hemp CBD',
      productCategoryId: 'hemp CBD_topicals',
    },
    pets_accessories: {
      prodCategory: 'Pet accessories',
      productCategoryGroup: 'Pets',
      productCategoryId: 'pets_accessories',
    },
    pets_capsules: {
      prodCategory: 'Pet capsules',
      productCategoryGroup: 'Pets',
      productCategoryId: 'pets_capsules',
    },
    pets_sprays: {
      prodCategory: 'Pet sprays',
      productCategoryGroup: 'Pets',
      productCategoryId: 'pets_sprays',
    },
    pets_tinctures: {
      prodCategory: 'Pet tinctures',
      productCategoryGroup: 'Pets',
      productCategoryId: 'pets_tinctures',
    },
    pets_treats: {
      prodCategory: 'Pet treats',
      productCategoryGroup: 'Pets',
      productCategoryId: 'pets_treats',
    },
    services_consulting: {
      prodCategory: 'Consulting',
      productCategoryGroup: 'Services',
      productCategoryId: 'services_consulting',
    },
    'services_design-build': {
      prodCategory: 'Design & build',
      productCategoryGroup: 'Services',
      productCategoryId: 'services_design-build',
    },
    services_financial: {
      prodCategory: 'Financial',
      productCategoryGroup: 'Services',
      productCategoryId: 'services_financial',
    },
    services_insurance: {
      prodCategory: 'Insurance',
      productCategoryGroup: 'Services',
      productCategoryId: 'services_insurance',
    },
    'services_label-packaging': {
      prodCategory: 'Label & packaging',
      productCategoryGroup: 'Services',
      productCategoryId: 'services_label-packaging',
    },
    services_legal: {
      prodCategory: 'Legal',
      productCategoryGroup: 'Services',
      productCategoryId: 'services_legal',
    },
    services_marketing: {
      prodCategory: 'Marketing',
      productCategoryGroup: 'Services',
      productCategoryId: 'services_marketing',
    },
    'services_pos-system-displays': {
      prodCategory: 'Pos system & displays',
      productCategoryGroup: 'Services',
      productCategoryId: 'services_pos-system-displays',
    },
    services_processing: {
      prodCategory: 'Processing',
      productCategoryGroup: 'Services',
      productCategoryId: 'services_processing',
    },
    'services_wholesale-distribution': {
      prodCategory: 'Whole & distribution',
      productCategoryGroup: 'Services',
      productCategoryId: 'services_wholesale-distribution',
    },
    tourism_events: {
      prodCategory: 'Events',
      productCategoryGroup: 'Tourism',
      productCategoryId: 'tourism_events',
    },
    tourism_lodging: {
      prodCategory: 'Lodging',
      productCategoryGroup: 'Tourism',
      productCategoryId: 'tourism_lodging',
    },
    tourism_package: {
      prodCategory: 'Package',
      productCategoryGroup: 'Tourism',
      productCategoryId: 'tourism_package',
    },
    tourism_tours: {
      prodCategory: 'Tours',
      productCategoryGroup: 'Tourism',
      productCategoryId: 'tourism_tours',
    },
  },
  productCategoryGroup: {
    CBD: 'CBD',
    Recreational: 'Recreational',
    Medical: 'Medical',
    Sustainability: 'Environmental Sustainability',
    Accessories: 'Accessories',
  },
  productCategory: {
    cbd_food: {
      prodCategory: 'Food & Beverage',
      productCategoryGroup: 'CBD',
      productCategoryId: 'cbd_food',
    },
    cbd_consumer: {
      prodCategory: 'Consumer Products',
      productCategoryGroup: 'CBD',
      productCategoryId: 'cbd_consumer',
    },
    cbd_pet: {
      prodCategory: 'Pet Care',
      productCategoryGroup: 'CBD',
      productCategoryId: 'cbd_pet',
    },
    cbd_cosmetics: {
      prodCategory: 'Cosmetics',
      productCategoryGroup: 'CBD',
      productCategoryId: 'cbd_cosmetics',
    },
    cbd_health: {
      prodCategory: 'Health & Wellness',
      productCategoryGroup: 'CBD',
      productCategoryId: 'cbd_health',
    },
    recreational_flower: {
      prodCategory: 'Flower',
      productCategoryGroup: 'Recreational',
      productCategoryId: 'recreational_flower',
    },
    recreational_delta8: {
      prodCategory: 'Delta-8 THC',
      productCategoryGroup: 'Recreational',
      productCategoryId: 'recreational_delta8',
    },
    recreational_edibles: {
      prodCategory: 'Edibles',
      productCategoryGroup: 'Recreational',
      productCategoryId: 'recreational_edibles',
    },
    recreational_cartridges: {
      prodCategory: 'Cartridges',
      productCategoryGroup: 'Recreational',
      productCategoryId: 'recreational_cartridges',
    },
    recreational_vaping: {
      prodCategory: 'Vaping',
      productCategoryGroup: 'Recreational',
      productCategoryId: 'recreational_vaping',
    },
    recreational_concentrates: {
      prodCategory: 'Concentrates',
      productCategoryGroup: 'Recreational',
      productCategoryId: 'recreational_concentrates',
    },
    recreational_prerolls: {
      prodCategory: 'Pre-rolls',
      productCategoryGroup: 'Recreational',
      productCategoryId: 'recreational_prerolls',
    },
    recreational_topicals: {
      prodCategory: 'Topicals',
      productCategoryGroup: 'Recreational',
      productCategoryId: 'recreational_topicals',
    },
    recreational_seeds: {
      prodCategory: 'Seeds',
      productCategoryGroup: 'Recreational',
      productCategoryId: 'recreational_seeds',
    },
    medical_phamaceuticals: {
      prodCategory: 'Pharmaceuticals',
      productCategoryGroup: 'Medical',
      productCategoryId: 'medical_phamaceuticals',
    },
    sustainability_agricultural: {
      prodCategory: 'Agricultural Product (Mulch & Compost)',
      productCategoryGroup: 'Environmental Sustainability',
      productCategoryId: 'sustainability_agricultural',
    },
    sustainability_building: {
      prodCategory: 'Building Materials',
      productCategoryGroup: 'Environmental Sustainability',
      productCategoryId: 'sustainability_building',
    },
    sustainability_green: {
      prodCategory: 'Green Packaging',
      productCategoryGroup: 'Environmental Sustainability',
      productCategoryId: 'sustainability_green',
    },
    sustainability_grocery: {
      prodCategory: 'Grocery Household',
      productCategoryGroup: 'Environmental Sustainability',
      productCategoryId: 'sustainability_grocery',
    },
    sustainability_hemp: {
      prodCategory: 'Hemp Plastics',
      productCategoryGroup: 'Environmental Sustainability',
      productCategoryId: 'sustainability_hemp',
    },
    sustainability_home: {
      prodCategory: 'Home & Gardening',
      productCategoryGroup: 'Environmental Sustainability',
      productCategoryId: 'sustainability_home',
    },
    sustainability_industrial: {
      prodCategory: 'Industrial Textile / Fiber (Rope, Canvas, Netting)',
      productCategoryGroup: 'Environmental Sustainability',
      productCategoryId: 'sustainability_industrial',
    },
    sustainability_livestock: {
      prodCategory: 'Livestock Bedding',
      productCategoryGroup: 'Environmental Sustainability',
      productCategoryId: 'sustainability_livestock',
    },
    sustainability_energy: {
      prodCategory: 'New Energy',
      productCategoryGroup: 'Environmental Sustainability',
      productCategoryId: 'sustainability_energy',
    },
    sustainability_materials: {
      prodCategory: 'New Materials',
      productCategoryGroup: 'Environmental Sustainability',
      productCategoryId: 'sustainability_materials',
    },
    sustainability_paper: {
      prodCategory: 'Paper Products (Printing, Newsprint, Cardboard, Packaging)',
      productCategoryGroup: 'Environmental Sustainability',
      productCategoryId: 'sustainability_paper',
    },
    sustainability_raw: {
      prodCategory: 'Raw Materials',
      productCategoryGroup: 'Environmental Sustainability',
      productCategoryId: 'sustainability_raw',
    },
    sustainability_texitle: {
      prodCategory: 'Texitle (Clothing, Diapers, Handbags, Denim, Shoes, Fine fabrics)',
      productCategoryGroup: 'Environmental Sustainability',
      productCategoryId: 'sustainability_texitle',
    },
    accessories_cultivation: {
      prodCategory: 'Cultivation Equipment',
      productCategoryGroup: 'Accessories',
      productCategoryId: 'accessories_cultivation',
    },
    accessories_dabbing: {
      prodCategory: 'Dabbing',
      productCategoryGroup: 'Accessories',
      productCategoryId: 'accessories_dabbing',
    },
    accessories_growing: {
      prodCategory: 'Growing',
      productCategoryGroup: 'Accessories',
      productCategoryId: 'accessories_growing',
    },
    accessories_lab: {
      prodCategory: 'Lab Testing Facilities',
      productCategoryGroup: 'Accessories',
      productCategoryId: 'accessories_lab',
    },
    accessories_smoking: {
      prodCategory: 'Smoking',
      productCategoryGroup: 'Accessories',
      productCategoryId: 'accessories_smoking',
    },
    accessories_storage: {
      prodCategory: 'Storage',
      productCategoryGroup: 'Accessories',
      productCategoryId: 'accessories_storage',
    },
  },
  productUnit: {
    ml: 'ml',
    L: 'L',
    g: 'g',
    kg: 'kg',
    oz: 'oz',
    piece: 'piece',
  },
  productAvailability: {
    pickup: 'Pick up only',
    delivery: 'Delivery only',
    pickup_delivery: 'Pickup & Delivery',
  },
  amenities: {
    1: 'Delivery',
    2: 'On-site pickup',
    3: 'Curbside pickup',
    4: 'Free parking',
    5: 'Reservation',
    6: 'Doctor onsite',
    7: 'Special offer',
    8: 'Accessible',
    9: 'Minimum age 18',
    10: 'Minimum age 19',
    11: 'Minimum age 21',
    12: 'Accept Credit Card',
    13: 'ATM',
    14: 'Lab tested',
    15: 'Medical',
    16: 'Brand Verified',
    17: 'Security',
    18: 'Photos',
    19: 'Videos',
  },
  strains: {
    1: 'AK-47',
    2: 'G13',
    3: 'Hendo Cream',
    4: 'Kosher Tangie',
    5: 'OG Kush',
    6: 'THC Bomb',
    7: 'Thin Mint Girl Scout Cookies',
    8: '78 OG LA Affie',
    9: 'Gravity',
    10: 'Crimea Blue',
    11: '818 OG',
    12: '8 Ball Kush',
    13: 'Frosty',
    14: 'Ice Queen',
    15: 'Cadillac Purple',
    16: 'California Orange',
    17: 'Chemdawg',
    18: 'Chemo',
    19: 'Northern Lights',
    20: 'Biker OG',
    21: 'Mimosa',
    22: 'Charlotte’s Web',
    23: 'Outer SPAce',
    24: 'Gorilla Glue #4',
    25: 'Great White Shark',
    26: 'Tahoe OG Kush',
    27: 'Amnesia White',
    28: 'Girl Scout Candy',
    29: 'Girl Scout Cookies',
    30: 'Curious George',
    31: 'Waikiki Queen',
    32: 'Hippie Chicken',
    33: 'Gemstone',
    34: 'Island Sweet Skunk',
    35: 'Clementine',
    36: 'Chocolate OG',
    37: 'Ghost Dawg',
    38: 'Star Killer',
    39: 'Yo 11',
    40: 'Lil Sebastian',
    41: 'Lemon Tree',
    42: 'Lemon Cake',
    43: 'Lemon Gelato',
    44: 'Lemon Haze',
    45: 'Cherry Do Si Dos',
    46: 'Tangie',
    47: 'Pure Kush',
    48: 'King Tut',
    49: 'Earth Kush',
    50: 'Deep Purple',
    51: 'Fuel OG',
    52: 'Grandaddy Purple',
    53: 'Glass Slipper',
    54: 'Swiss Cheese',
    55: 'White Nightmare',
    56: 'White Widow',
    57: 'White Sangria',
    58: 'Jack Wreck',
    59: 'Canna Tsu',
    60: 'Godberry',
    61: 'Gumbo',
    62: 'Purple Kush',
    63: 'Purple Punch',
    64: 'Purple Monkey',
    65: 'Purple Glue',
    66: 'Green Goblin',
    67: 'Blue Zkittles',
    68: 'Green Poison',
    69: 'Green Lantern',
    70: 'Green Candy',
    71: 'Green Crack',
    72: 'Sin Mint Cookies',
    73: 'Bubba OG',
    74: 'Bubba Kush',
    75: 'Green Crack Extreme',
    76: 'Dolato',
    77: 'SFV OG',
    78: 'Mango Kush',
    79: 'Endless Sky',
    80: 'Strawberry Fields',
    81: 'Strawnana',
    82: 'Dutch Queen',
    83: 'Pineapple Express',
    84: 'Grapefruit Diesel',
    85: 'Grape OX',
    86: 'Grapefruit Mimosa',
    87: 'Grape Skunk',
    88: 'Blue Satellite',
    89: 'Blue Bastard',
    90: 'Blue Dream',
    91: 'Blue Dynamite',
    92: 'Blue Dot',
    93: 'Blueberry Limeade',
    94: 'Blueberry',
    95: 'Blue Afghani',
    96: 'Blue Magic',
    97: 'Blood Drive',
    98: 'Harlequin',
    99: 'Super Green Crack',
    100: 'Super Skunk',
    101: 'King Louis XIII',
    102: 'Sour Diesel',
    103: 'Sour Banana Sherbet',
    104: 'Zkittlez',
    105: 'Goldberry',
    106: 'Milky Way',
    107: 'Gelato',
    108: 'Gelato 45',
    109: 'Gelato Glue',
    110: 'Lucy Diamond',
    111: 'Banana Cookies',
    112: 'Jack Skellington',
    113: 'Black 84',
    114: 'Black Diesel',
    115: 'Skunkberry',
    116: "Dragon's Breath",
    117: 'Remedy',
    118: 'Sour Tsunami',
    119: 'ACDC',
    120: 'CBD Mango Haze',
    121: 'Mango Haze',
    122: 'Northern Lights #5',
    123: 'Kush Co OG',
    124: 'Mendo Breath',
    125: 'Tora Bora',
    126: 'Grape Stomper',
    127: 'Salmon River OG',
    128: 'Blue Alien',
    129: 'Avi-Dekel',
    130: 'GI001',
    131: 'Harle Tsu',
    132: 'OG Tonic',
    133: 'Rafael',
    134: 'Valentine X',
    135: 'VCDC',
    136: 'Death Star OG',
    137: 'Cheese',
    138: 'Jack Herer',
    139: 'Sour Pink Grapefruit',
    140: 'Afghooey',
    141: 'Channel 2',
    142: 'MediHaze',
    143: 'Fire Angel',
    144: 'Trident',
    145: 'Khola',
    146: 'Nebula II CBD',
    147: 'Nordle',
    148: 'Maui Bubble Gift',
    149: 'Skunk Haze',
    150: 'Star Tonic',
    151: 'Aceh',
    152: 'Argyle',
    153: 'Central American',
    154: 'Haoma Mist',
    155: "Ringo's Gift",
    156: 'Ringos Gift',
    157: 'Negra 44',
    158: 'Beetlejuice',
    159: 'Black Dahlia',
    160: 'Cat Piss',
    161: 'Funky Monkey',
    162: 'Grape Crush',
    163: 'Violet Delight',
    164: 'Avi',
    165: 'Bandana',
    166: 'Blue Moonshine',
    167: 'Cali Kush',
    168: 'Ogre',
    169: 'Pink Pez',
    170: "DJ Short's Flo",
    171: 'Allen Wrench',
    172: 'Atomic Northern Lights',
    173: 'Flo',
    174: 'Lavender',
    175: 'Mr. Nice',
    176: 'Northern Skunk',
    177: 'Peaches And Cream',
    178: 'Pearl Scout Cookies',
    179: 'Purple Princess',
    180: 'Royal Highness',
    181: 'Sexxpot',
    182: 'SPAce Bomb',
    183: 'Sweet Tooth',
    184: '3 Kings',
    185: 'Aberdeen',
    186: 'Black Tuna',
    187: 'Blueberry Diesel',
    188: 'Blueberry Yum Yum',
    189: 'Dark Wizard',
    190: 'Donna OG',
    191: 'El Nino',
    192: 'Electric Lemonade',
    193: 'Emperor OG',
    194: 'Maramota 10',
    195: 'Maui Berry',
    196: 'MK Ultra',
    197: 'NY Cheese',
    198: 'Purple Dream',
    199: 'Qrazy Train',
    200: 'Raspberry Kush',
    201: 'Red Berry Cherry',
    202: 'Sierra Mist',
    203: 'SPAce Queen',
    204: 'Valley Ghash',
    205: 'Vanilla Kush',
    206: 'Afpac',
    207: 'Cherry AK-47',
    208: 'Goldwing',
    209: 'Mars OG',
    210: 'Master Kush',
    211: 'New Jack',
    212: 'Obama Kush',
    213: 'Platinum Wreck',
    214: 'Querkle',
    215: 'Romulan',
    216: 'Royal Dwarf',
    217: 'Super Lemon Haze',
    218: 'Super Silver Haze',
    219: 'White Cheese',
    220: 'Afghan Diesel',
    221: 'Bubble Gum',
    222: 'Diamond OG',
    223: 'Jet Fuel',
    224: 'Maple Leaf',
    225: 'OGs Pearl',
    226: 'Platinum OG',
    227: 'Purple Gorilla',
    228: 'Purple Urkle',
    229: 'Purple Voodoo',
    230: 'Raskal OG',
    231: 'Sour Dream',
    232: 'Sour OG',
    233: 'Sour Willie',
    234: 'Sunset Haze',
    235: 'Strawberry Lemonade',
    236: 'Apollo 13',
    237: 'Appalachia',
    238: "Art's OG",
    239: 'Blackberry Trainwreck',
    240: 'Blue Diamond',
    241: 'Exodus Cheese',
    242: 'Fruit Spirit',
    243: 'Jack Widow',
    244: 'Lemon Fire Kush',
    245: 'Mad Dawg',
    246: 'Orange Kush',
    247: 'Purple AK-47',
    248: 'Purple Ice',
    249: 'Purple Skunk',
    250: 'Purple Trainwreck',
    251: 'Quebec Gold',
    252: 'Tangilope',
    253: 'Sweet Kush',
    254: 'Lemon Skunk',
    255: 'Pootie Tang',
    256: 'Devils Lettuce',
    257: 'Caramel Apple Kush',
    258: 'Cherry Chem',
    259: '13 Dawgs',
    260: 'Animal Cookies',
    261: 'Cali Gold',
    262: 'Church OG',
    263: 'Ed Rosenthal',
    264: 'Harmony',
    265: 'Hustler Kush',
    266: 'Jack Kush',
    267: 'LA Woman',
    268: 'OG Diesel Kush',
    269: 'Panda OG',
    270: 'Paris OG',
    271: 'Pineapple',
    272: 'Pineapple Dog Shit',
    273: 'Platinum Bubba Kush',
    274: 'Purple OG Kush',
    275: 'Red Diesel',
    276: 'Satellite OG',
    277: 'Sugar Mama',
    278: 'Sugar Shack',
    279: 'Super Sour Diesel',
    280: 'Trainwreck',
    281: 'White Lavender',
    282: 'Sour Apple Bubba',
    283: 'SPAce Cake',
    284: 'Motorbreath',
    285: 'Afghanica',
    286: 'Afwreck',
    287: 'Apple Jack',
    288: 'Apple Kush',
    289: 'Area 51',
    290: 'Atomic Goat',
    291: 'Banana Diesel',
    292: 'Beyond Blue Dream',
    293: 'Blackberry',
    294: 'Blue Boy',
    295: 'Blue Champagne',
    296: 'Blue God',
    297: 'Blue Lights',
    298: 'Cookie Wreck',
    299: 'Face Off OG',
    300: 'Fire Haze',
    301: 'Grand Hindu',
    302: 'Green Hornet',
    303: "Hell's OG",
    304: 'Hellfire OG',
    305: 'Hollywood OG',
    306: 'Humboldt OG',
    307: 'Ice',
    308: 'Jack Haze',
    309: 'Julius Caesar',
    310: 'Knockout OG',
    311: 'Lake Of Fire',
    312: 'Liquid Butter',
    313: 'Master Yoda',
    314: 'NY-47',
    315: 'OG Shark',
    316: 'OG Skunk',
    317: 'Old School OG',
    318: 'Orange Haze',
    319: 'Pink Kush',
    320: 'Presidential OG',
    321: 'Redd Cross',
    322: 'Seattle Blue',
    323: 'Shark Shock',
    324: 'Somango',
    325: 'Tres Dawg',
    326: 'White Dawg',
    327: 'White Lotus',
    328: 'Sunny D',
    329: 'La Platina',
    330: 'Holy Grail',
    331: 'Pre 98 Bubba Kush',
    332: 'Orange Cookies',
    333: 'Old School Lemons',
    334: 'Sour Joker',
    335: 'Cracker Jack',
    336: 'Glue on Fire',
    337: 'Virtue OG',
    338: '401k',
    339: 'Golden Ticket',
    340: '501st OG',
    341: 'Snake Eyes OG',
    342: '707 Headband',
    343: 'Balmoral',
    344: 'Blue Crack',
    345: 'Blue Mystic',
    346: 'Critical Haze',
    347: 'Critical Plus',
    348: 'Dairy Queen',
    349: 'Danky Doodle',
    350: 'Do-Over OG',
    351: 'Frostbite',
    352: 'Frosted Freak',
    353: 'Game Changer',
    354: 'Gorilla Cookies',
    355: 'Guava Chem',
    356: 'Guava Kush',
    357: 'Hawaiian Punch',
    358: "J's Famous Kush",
    359: "King's Bread",
    360: 'Kings Bread',
    361: 'Pakistani Chitral Kush',
    362: 'Tangelo Kush',
    363: 'The Third Dimension',
    364: 'X-Files',
    365: 'Tahoe Lemonade',
    366: 'Alien Cookies',
    367: 'Cherry Diesel',
    368: 'Watermelon Tree',
    369: 'Tropicana Cookies',
    370: 'Black Mamba',
    371: 'SPArtan Snow',
    372: 'Fluffhead',
    373: 'Sundae Driver',
    374: 'Pure Haze',
    375: 'Purple Haze',
    376: 'Triple G',
    377: 'Stardawg',
    378: 'White Fire OG',
    379: 'Cheesecake',
    380: 'Key Lime Pie',
    381: 'Sunset Sherbet',
    382: 'Burkle',
    383: '$100 OG',
    384: 'Blue Frost',
    385: 'Blue Goo',
    386: 'Cannadential',
    387: 'Double Diesel',
    388: 'Earthshaker OG',
    389: 'Easy Like Sunday Morning',
    390: 'Elvis',
    391: 'Irene Kush',
    392: 'Kimchi OG',
    393: 'Lemon OG',
    394: 'Lemonberry',
    395: 'Lucky Charms',
    396: 'Mendocino Purps',
    397: 'OG LA Affie',
    398: 'OG Poison',
    399: 'P1',
    400: 'Poison OG',
    401: 'Rainbow',
    402: 'Scooby Snacks',
    403: 'The Truth',
    404: 'True OG',
    405: 'White Dragon',
    406: 'Supernatural',
    407: 'Lime Skunk',
    408: 'Anslinger',
    409: 'Chunkberry',
    410: 'Miracle Whip',
    411: 'Secret Weapon',
    412: 'Cactus OG',
    413: 'Lemon Meringue',
    414: 'Orange Zkittlez',
    415: 'Palmdale Purple',
    416: 'Purple Cactus',
    417: 'Charlie Sheen',
    418: 'Dr. Who',
    419: 'Golden Goat',
    420: 'Grateful Breath',
    421: 'Irene OG',
    422: 'Jack Frost',
    423: 'Locktite',
    424: 'Marionberry Kush',
    425: 'Medicine Man',
    426: 'Neptune OG',
    427: 'Reclining Buddha',
    428: 'Recon',
    429: 'Sour Cream',
    430: 'Supermax OG',
    431: 'Star Pie',
    432: '24K Gold',
    433: "Zelly's Gift",
    434: '1991 OG',
    435: 'Stardust OG',
    436: 'Do Si Dos',
    437: 'Purple Headband',
    438: 'Black Afghan',
    439: 'Banana Candy',
    440: 'Birthday Cake',
    441: 'Blue Hawaiian',
    442: 'Blue Moon Rocks',
    443: 'Cream Caramel',
    444: 'Fortune Cookies',
    445: 'G Stik Amarillo',
    446: 'Green Dream',
    447: 'Jane Doe',
    448: 'Jillybean',
    449: 'Morning Star',
    450: 'Paris XXX',
    451: "Savant's Grail",
    452: 'Sherbert',
    453: 'Snowland',
    454: 'Special K',
    455: 'Sugar Punch',
    456: 'Tahoe Alien',
    457: 'White Lightning',
    458: 'Truth Serum',
    459: 'Golden Tangie',
    460: 'Love Triangle',
    461: 'Fruitcake',
    462: 'Desert Diesel',
    463: 'Deadhead OG',
    464: 'Emerald Cookies',
    465: 'G Stik Rosado',
    466: 'Grape OG',
    467: 'Hawaiian Fire',
    468: 'Head Cheese',
    469: 'Papas OG',
    470: 'Wally OG',
    471: 'White Fire 43',
    472: 'White Kryptonite',
    473: 'White Slipper',
    474: 'Supernova',
    475: 'Banana Cream',
    476: 'Sour Grapefruit',
    477: 'White Valley OG',
    478: 'NYC Diesel',
    479: 'Mandarin Mint',
    480: 'GMO',
    481: 'Black Betty',
    482: 'Blue Cookies',
    483: 'Blue Knight',
    484: 'Cabbage Patch',
    485: 'Cookies and Cream',
    486: 'Dream Beaver',
    487: 'Hawaiian Haze',
    488: 'Monster OG',
    489: 'Montana Silvertip',
    490: 'Wellness OG',
    491: 'White Fire Alien OG',
    492: 'White Knight',
    493: 'Boss Hog',
    494: 'Black Cherry Pie',
    495: 'Kosher Kush',
    496: 'Desert Snow',
    497: "L'Orange",
    498: 'Asteroid',
    499: 'Desert Unicorn',
    500: 'Tropicanna',
    501: 'Asteroid OG',
    502: 'Candy Jack',
    503: 'Quantum Kush',
    504: 'Sour Bubble',
    505: 'Funky Malawi',
    506: 'Bio Jesus',
    507: 'A-Dub',
    508: 'Alien OG',
    509: 'Alohaberry',
    510: 'Black Widow',
    511: 'Holy Ghost',
    512: 'Micky Kush',
    513: 'OG Ringo',
    514: 'The Sauce',
    515: 'Jenny Kush',
    516: 'Blissful Wizard',
    517: 'Scotts OG',
    518: '3 Pac',
    519: 'Inferno OG',
    520: 'Kush Mountains',
    521: 'King Kong Cookies',
    522: 'Miss X',
    523: 'Pure OG',
    524: 'AC DC',
    525: 'CBD Kush',
    526: 'BC Sweet tooth',
    527: 'Rene Mist',
    528: 'Monster Cookies',
    529: 'Super Sour Skunk',
    530: 'Sour Grape',
    531: 'Sweet Diesel',
    532: 'Blueberry Headband',
    533: 'Lavender Kush',
    534: 'Purple Jack',
    535: 'Haoma',
    536: 'Lashkar Gah',
    537: 'Hawaiian Skunk',
    538: 'BC Roadkill',
    539: 'Betty Page',
    540: 'Purple Alien OG',
    541: 'Alaskan Thunder Fuck',
    542: 'California Grapefruit',
    543: 'Dance World',
    544: 'Phantom Cookies',
    545: 'BC Big Bud',
    546: 'Blue Widow',
    547: 'Blueberry Jack',
    548: 'Lions Gate',
    549: 'Orange Skunk',
    550: 'Double Purple Doja',
    551: 'Early Girl',
    552: 'Malawi Gold',
    553: 'Mau-Mau',
    554: 'Ultimate Indica',
    555: 'Fruity Pebbles',
    556: 'XXX OG',
    557: 'G13 Widow',
    558: 'Afghan Big Bud',
    559: 'LA OG',
    560: 'Super Sweet',
    561: 'Alpha Blue',
    562: 'Cotton Candy Kush',
    563: 'Skywalker',
    564: 'Agent Orange',
    565: 'Ambrosia',
    566: 'Dark Star',
    567: 'Sour Alien',
    568: 'Afghan Kush',
    569: 'Faygo Red Pop',
    570: 'Abusive OG',
    571: 'American Dream',
    572: 'Big Buddha Cheese',
    573: 'Blue OG',
    574: 'Chernobyl',
    575: 'Grape Kush',
    576: 'Pineapple Diesel',
    577: 'Strawberry Cough',
    578: 'Super Snow Dog',
    579: 'Big Bang',
    580: 'The Flav',
    581: 'Whitewalker OG',
    582: 'Blackberry Kush',
    583: 'California Sour',
    584: 'Silver Pearl',
    585: 'SFV OG Kush',
    586: 'Forbidden Fruit',
    587: 'Eugene Cream',
    588: 'Jasmine',
    589: 'KC 33',
    590: 'Orange Diesel',
    591: 'Red Poison',
    592: 'Sour Candy',
    593: 'Afghani',
    594: 'Lamborghini',
    595: 'Lavender Jones',
    596: 'Predator Pink',
    597: 'Rockstar',
    598: 'Sour Apple',
    599: 'Walrus Kush',
    600: '3X Crazy',
    601: 'Pink Mango',
    602: 'The Void',
    603: 'Wreckage',
    604: 'Aurora',
    605: 'Avalon',
    606: 'Candyland',
    607: 'Cheese Candy',
    608: 'Flowerbomb Kush',
    609: 'King Kong',
    610: 'Purple Crack',
    611: 'Critical Kush',
    612: 'Starbud',
    613: 'Warlock',
    614: 'Yumboldt',
    615: 'Nice Cherry',
    616: 'Headband',
    617: 'Odyssey',
    618: 'Oregon Diesel',
    619: 'Richie Rich',
    620: 'Yoda OG',
    621: 'Industrial Plant',
    622: 'Black Jack',
    624: 'Grapefruit Haze',
    625: 'Grape God',
    626: "King's Kush",
    627: 'Haze',
    628: 'Cerebro Haze',
    629: 'Sensi Star',
    630: 'Armageddon',
    631: 'Maui Haole',
    632: 'Monkey Paw',
    633: 'Ortega',
    634: 'The One',
    635: 'Honey Bananas',
    636: 'LA Jack',
    637: 'OG Cheese',
    638: 'Rene',
    639: 'Skunky Diesel',
    640: 'Verde Electric',
    641: 'Albert Walker',
    642: 'Alien Rock Candy',
    643: 'Catfish',
    644: 'Dream Queen',
    645: 'Goo',
    646: 'Grimace',
    647: 'Kill Bill',
    648: 'Opal OG Kush',
    649: 'Skunk #1',
    650: 'Tropicali',
    651: 'Waldo',
    652: 'White Zombie',
    653: 'Aurora Borealis',
    654: 'Berry Bomb',
    655: 'Berry OG',
    656: 'Black Velvet',
    657: 'Bubbleberry',
    658: 'Cactus',
    659: 'Candy Cane',
    660: 'Early Pearl',
    661: 'Emerald Jack',
    662: 'Euphoria',
    663: 'Fire OG',
    664: 'Ghost OG',
    665: 'Grapefruit',
    666: 'Grapefruit Kush',
    667: 'Green Queen',
    668: 'Jack Flash',
    669: 'Mango',
    670: 'Mazar I Sharif',
    671: 'Medusa',
    672: 'Pez',
    673: 'Silver Bubble',
    674: 'Starfighter',
    675: 'Afghani Bullrider',
    676: 'Ancient OG',
    677: 'Chocolope',
    678: 'Kandy Kush',
    679: 'Lambo OG',
    680: 'Pineapple Skunk',
    681: 'Tangerine Power',
    682: 'Afghan Haze',
    683: 'Conspiracy Kush',
    684: 'DJ Short Blueberry',
    685: 'Gatekeeper OG',
    686: 'Snowcap',
    687: 'Super Kush',
    688: 'Tembo Kush',
    689: 'Alice in Wonderland',
    690: 'Banana Fire OG',
    691: 'Ghost Bubba',
    692: 'Melon Gum',
    693: 'Durban Poison',
    694: 'Omega Dawg',
    695: 'Toxic Punch',
    696: 'B4',
    697: 'Big Buddha Haze',
    698: 'Blue Nightmare',
    699: 'Cambodian',
    700: 'Champagne Kush',
    701: 'Sour Maui',
    702: 'Ace Of SPAdes',
    703: 'Big Bud',
    704: 'Blue Cheese',
    705: 'Acapulco Gold',
    706: 'Hawaiian',
    707: 'Frisian Dew',
    708: 'Ultra Sonja',
    709: 'Banana Kush',
    710: 'Bird’s Eye',
    711: 'Cherry Lime Haze',
    712: 'Cherry Pie',
    713: 'Dutch Treat',
    714: 'Platinum Kush',
    715: 'Snow Lotus',
    716: 'Bakerstreet',
    717: 'Big Wreck',
    718: "Dawg's Waltz",
    719: 'A-Train',
    720: 'Afgoo',
    721: 'Amethyst',
    722: 'Apollo 11',
    723: 'Arcata Trainwreck',
    724: 'Armagnac',
    725: 'Black Russian',
    726: 'Blackberry Haze',
    727: 'Dream Berry',
    728: 'Juicy Fruit',
    729: 'SPAce Needle',
    730: 'Strawberry Blue',
    731: 'Blue Power',
    732: 'Purple Diesel',
    733: 'Tangerine Haze',
    734: 'Berry White',
    735: 'Heaven Scent',
    736: 'Ice Cream',
    737: 'White Russian',
    738: 'XJ 13',
    739: 'OG 18',
    740: 'Blackberry Lime Haze',
    741: 'LSD',
    742: 'Madman OG',
    743: 'Purple Sage',
    744: 'Pineapple Chunk',
    745: 'Rug Burn OG',
    746: 'Grape Ape',
    747: 'LA Confidential',
    748: 'Neptune Kush',
    749: 'Sour Dubble',
    750: 'The Sister',
    751: 'Zeus OG',
    752: '619 Saturn OG',
    753: 'Caramelicious',
    754: 'Frankenberry',
    755: 'Lemon Wreck',
    756: 'Saturn OG',
    757: 'Bay 11',
    758: 'Amnesia Haze',
    759: 'Damn Sour',
    760: 'SFG OG',
    761: 'Venom OG',
    762: 'Blockhead',
    763: 'Blue Diesel',
    764: 'Ewok',
    765: 'Superstar',
    766: 'Blueberry Kush',
    767: 'Flowers for Algernon',
    768: "Mother's Helper",
    769: 'Kali Dog',
    770: 'American Kush',
    771: 'Chemdawg 4',
    772: 'Dutch Hawaiian',
    773: 'Jupiter OG',
    774: "Ken's Kush",
    775: 'Astroboy',
    776: 'Wappa',
    777: 'Blackwater',
    778: 'Blue Velvet',
    779: 'Green Ribbon',
    780: 'Sugar Kush',
    781: 'Fruity Pebbles OG',
    782: 'Ganesh Berry',
    783: 'Hawaiian Cookies',
    784: 'Hawaiian Thunder Fuck',
    785: 'Lemon Cookies',
    786: 'Night Terror OG',
    787: 'Zombie OG',
    788: 'Sweet Cheese',
    789: 'Black Diamond',
    790: 'Cinderella 99',
    791: 'Frankenstein',
    792: 'Haze Heaven',
    793: 'Martian Mean Green',
    794: 'SPAce Candy',
    795: 'Tangerine',
    796: 'White Rhino',
    797: 'White Skunk',
    798: 'Zen',
    799: 'J1',
    800: 'Lemon Burst',
    801: 'Marionberry',
    802: 'Shoreline',
    803: 'SPAce Dawg',
    804: 'A-10',
    805: 'Bay Dream',
    806: 'Black Domina',
    807: 'Elderberry Kush',
    808: 'Jack 47',
    809: 'Platinum GSC',
    810: 'Ghost Train Haze',
    811: '303 OG',
    812: 'Death Star',
    813: 'Galactic Jack',
    814: '9 Pound Hammer',
    815: 'OG Strawberry',
    816: 'Skywalker OG',
    817: 'Hawaiian Snow',
    818: 'Atmosphere',
    819: 'Caramelo',
    820: 'Diablo',
    821: 'East Coast Sour Diesel',
    822: 'Kaia Kush',
    824: 'Madzilla',
    825: 'MILF',
    826: 'Red Eye OG',
    827: 'LA Kush',
    828: 'Ray Charles',
    829: 'Cherry OG',
    830: 'Critical Mass',
    831: 'Peppermint Cookies',
    832: 'G13 Haze',
    833: 'Ogre OG',
    834: 'Superman OG',
    835: 'Throwback Kush',
    836: 'AK-48',
    837: 'Alien Asshat',
    838: 'Alpha OG',
    839: 'Black Cherry Soda',
    840: 'Stevie Wonder',
    841: 'Liberty Haze',
    842: 'Tangerine Dream',
    843: 'Yogi Diesel',
    844: 'Sour Haze',
    845: 'LA Ultra',
    846: 'Super Blue Dream',
    847: 'Arjan’s Strawberry Haze',
    848: 'Double OG',
    849: 'Earth OG',
    850: 'GriZ Kush',
    851: 'Petrolia Headstash',
    852: 'SPAce Jill',
    853: 'Strawberry Cheesecake',
    854: 'Super Lemon OG',
    855: 'White Diesel',
    856: 'Nightfire OG',
    857: 'UK Cheese',
    858: 'Bio Diesel',
    859: 'Catarack Kush',
    860: 'Phantom OG',
    861: 'Strawberry Diesel',
    862: 'The Grunk',
    863: '1024',
    864: 'Blackberry Cream',
    865: 'Cookie Face',
    866: 'Papaya',
    867: 'Pink Cookies',
    868: 'White Queen',
    869: 'Blowfish',
    870: 'Dutch Crunch',
    871: 'Lemon Kush',
    872: '91 Chemdawg',
    873: 'Alien Sour Apple',
    874: 'Cannalope Haze',
    875: 'Khalifa Kush',
    876: 'SAGE',
    877: 'The White',
    878: 'Alaska',
    879: 'Bruce Banner',
    880: 'Kush Master',
    881: 'Marcosus Marshmellow',
    882: 'Sour Tangie',
    883: 'Ladyburn 1974',
    884: 'Ocean Grown Kush',
    885: 'Professor Chaos',
    886: 'Tokyo OG',
    887: 'Sour Kush',
    888: 'Primus',
    889: 'Dirty Girl',
    890: 'Black Ice',
    891: 'Doug’s Varin',
    892: 'Mad Scientist',
    893: 'Pink Panther',
    894: 'Sour Power',
    895: 'Banana Split',
    896: 'El Jefe',
    897: 'Cinex',
    898: 'Ripped Bubba',
    899: 'Funfetti',
    900: 'Hell Monkey',
    901: 'LA Chocolat',
    902: 'Lucid Dream',
    903: "Marley's Collie",
    904: 'Satori',
    905: 'Banana OG',
    906: "Dirk's OG",
    907: 'Juicy Jack',
    908: 'Larry OG',
    909: 'Dogwalker OG',
    910: 'LAPD',
    911: 'Mango Tango',
    912: 'Berkeley',
    913: 'Day Tripper',
    914: 'Disney Blue',
    915: 'Ebola 7',
    916: 'Green Haze',
    917: 'Lime Purple Mist',
    918: 'Ogre Berry',
    919: 'Dank Schrader',
    920: 'Headbanger',
    921: 'Golden Pineapple',
    922: "Elmer's Glue",
    923: 'Sensi Skunk',
    924: 'Kimbo Kush',
    925: 'Cannatonic',
    926: 'Air Force One',
    927: 'Casino Kush',
    928: 'Hawaiian Dream',
    929: 'Stephen Hawking Kush',
    930: 'Bhang 707 Headband',
    931: 'Master Bubba',
    932: 'Red Dwarf',
    933: 'Dieseltonic',
    934: 'El-Na',
    935: 'Canna-Tsu',
    936: 'B-52',
    937: 'Pennywise',
    938: 'Afgooey',
    939: 'Ash',
    940: 'Banana Peel',
    941: 'Beastmode OG',
    942: 'Belladonna',
    943: 'Blue Dream Haze',
    944: 'Blue Kush',
    945: 'Bordello',
    946: 'C4',
    947: 'Cactus Cooler',
    948: 'Carl Sagan',
    949: 'Cheesewreck',
    950: 'Damnesia',
    951: 'Dancehall',
    952: 'Deep Cheese',
    953: 'Desert Star',
    954: 'Dog Shit',
    955: 'Dream Star',
    956: 'Earthquake',
    957: 'Empire Kush',
    958: 'Flight 813',
    959: 'G Stik Orange',
    960: 'G13 Diesel',
    961: 'Golden Panda',
    962: 'Grand Hustle',
    963: 'Grand Reserve Headband',
    964: 'Grape Krush 2',
    965: 'Green Kush',
    966: 'Guido Kush',
    967: 'Haole',
    968: 'Hippie Crippler',
    969: "Jack's Dream",
    970: 'Jah Kush',
    971: 'Jean Guy',
    972: 'Josh D OG Kush',
    973: 'K1',
    974: 'K2',
    975: 'Kalashnikova',
    976: 'Killer Grape',
    977: 'Kong',
    978: 'Kushage',
    979: 'Lemon Alien Dawg',
    980: 'Lemon Larry OG',
    981: 'Lemon OG Haze',
    982: 'Leroy OG',
    983: 'Lifesaver',
    984: 'Limon',
    985: 'Loopy Fruit',
    986: 'Loud Dream',
    987: 'Loud Scout',
    988: 'Manitoba Poison',
    989: 'Martian Candy',
    990: 'Master Skunk',
    991: 'Mendo Kush',
    992: 'Nebula',
    993: 'Northern Lights X Skunk',
    994: 'OG Chem',
    995: 'OG Wreck',
    996: 'Papaya Kush',
    997: 'Permafrost',
    998: 'Pineapple Purple Skunk',
    999: 'Pink Lemonade',
    1000: 'Pure Gold',
    1001: 'Purple Cheese',
    1002: 'Purple Hashplant',
    1003: 'Purple Maui',
    1004: 'Purple Sticky Punch',
    1005: 'Redding OG',
    1006: 'Sapphire Star',
    1007: 'Secret Recipe',
    1008: "Shark's Breath",
    1009: 'Shiatsu Kush',
    1010: 'Silver Surfer',
    1011: 'Sin Valley OG',
    1012: 'Starry Night',
    1013: 'Thai Lights',
    1014: 'Thaidal Wave',
    1015: 'Valley Girl',
    1016: 'Yummy',
    1017: 'Gucci OG',
    1018: 'Omega',
    1019: '5th Element',
    1020: 'Afrodite',
    1021: 'Afternoon Delight',
    1022: 'Agent Tangie',
    1023: 'Alien Dawg',
    1024: 'Alien Kush',
    1025: 'Alien Reunion',
    1026: 'Alien Technology',
    1027: 'Alpine Blue',
    1028: 'Amethyst Bud',
    1029: 'Aphrodite',
    1030: 'Apocalypto',
    1031: 'Big Sur Holy Bud',
    1032: 'Biscotti',
    1033: 'Black Cherry Cheesecake',
    1034: 'Black Lime',
    1035: 'Blackberry Hashplant',
    1036: 'Blackberry Rhino',
    1037: 'Blue Blood',
    1038: 'Blue Magoo',
    1039: 'Blue Maui',
    1040: 'Blue Venom',
    1041: 'Blue Wreck',
    1042: 'Blueberry Cheesecake',
    1043: 'Blueberry Cookies',
    1044: 'Blueberry Essence',
    1045: 'Blueberry Haze',
    1046: 'Blueberry Pie',
    1047: 'Blues',
    1048: 'Booger',
    1049: 'Boysenberry',
    1050: 'Brainstorm Haze',
    1051: 'Bubba Skywalker',
    1052: 'Bubble Cheese',
    1053: 'Bubblegun',
    1054: 'Buddha Tahoe',
    1055: 'C13 Haze',
    1056: 'Cactus Breath',
    1057: 'Casey Jones',
    1058: 'Chem Valley Kush',
    1059: 'Chemdawg Sour Diesel',
    1060: 'Cherry Gorilla',
    1061: 'Cherry Kush',
    1062: 'Chocolate Diesel',
    1063: 'Chocolate Fondue',
    1064: 'Chocolate Mint OG',
    1065: 'Chocolate Thunder',
    1066: 'Chronic',
    1067: 'Chupacabra',
    1068: 'Citrix',
    1069: 'Citrus Kush',
    1070: 'Citrus Sap',
    1071: 'Confidential Cheese',
    1072: 'Corleone Kush',
    1073: 'Cornbread',
    1074: 'Critical Cheese',
    1075: 'Critical Hog',
    1076: 'Critical Jack',
    1077: 'Critical Widow',
    1078: 'Crown OG',
    1079: 'Crystal Coma',
    1080: 'Darth Vader OG',
    1081: 'DelaHaze',
    1082: 'Devil Fruit',
    1083: 'Diamond Dust',
    1084: 'Digweed',
    1085: 'Django',
    1086: 'Dopium',
    1087: 'Eastern European',
    1088: 'Edelweiss',
    1089: 'Electric Ginger',
    1090: 'Emerald OG',
    1091: 'Fruity Chronic Juice',
    1092: 'G Stik Verde',
    1093: 'Garlic Bud',
    1094: 'Gigabud',
    1095: 'God Bud',
    1096: "God's Gift",
    1097: 'Godfather OG',
    1098: 'Goji OG',
    1099: 'Gorilla Biscuit',
    1100: 'Grandpa Larry OG',
    1101: 'Grape Pie',
    1102: 'Grape Valley Kush',
    1103: 'Hardcore OG',
    1104: 'Hash Plant',
    1105: 'Hashberry',
    1106: 'Hashplant Haze',
    1107: 'Hawaiian Diesel',
    1108: 'Hawaiian Sativa',
    1109: 'Haze Mist',
    1110: 'Haze Wreck',
    1111: 'Head Trip',
    1112: 'Heisenberg Kush',
    1113: 'Hells Angel OG',
    1114: 'Herijuana',
    1115: 'Hindu Kush',
    1116: 'Hindu Skunk',
    1117: "Hog's Breath",
    1118: "Holland's Hope",
    1119: 'Honey Boo Boo',
    1120: 'Hope',
    1121: 'Iced Widow',
    1122: 'Incredible Bulk',
    1123: 'Ingrid',
    1124: 'Inner Chi',
    1125: 'J-27',
    1126: 'Jack Diesel',
    1127: 'Jack The Ripper',
    1128: "Jack's Cleaner",
    1129: 'Jacky White',
    1130: 'Jamaican Dream',
    1131: 'Jedi Kush',
    1132: 'Jesus OG',
    1133: 'Jet Setter OG',
    1134: 'Jock Horror',
    1135: 'Joseph OG',
    1136: 'Jr',
    1137: 'JT15',
    1138: 'Jupiter Kush',
    1139: 'K-Train',
    1140: 'Kahuna',
    1141: 'Kali China',
    1142: 'Kali Mist',
    1143: 'Kandahar',
    1144: 'Katsu Kush',
    1145: 'Killa Kush',
    1146: 'Killing Fields',
    1147: 'Kobain Kush',
    1148: 'Kona Gold',
    1149: 'Kookies',
    1150: 'Kryptonite',
    1151: 'Kushadelic',
    1152: 'Kushberry',
    1153: 'Lady Liberty',
    1154: 'Lambs Bread',
    1155: 'Lemon Bubble',
    1156: 'Lemon Head',
    1157: 'Lemon Jack',
    1158: 'Lemon Sativa',
    1159: 'Lemonhead OG',
    1160: 'M-39',
    1161: 'Mad Man',
    1162: 'Madagascar',
    1163: 'Mako Haze',
    1164: 'Malawi',
    1165: 'Mango Dream',
    1166: 'Master Jedi',
    1167: 'Maui Waui',
    1168: 'Mercury OG',
    1169: 'Misty Kush',
    1170: 'Money Maker',
    1171: 'Moonshine Haze',
    1172: 'Mother Of Berries',
    1173: "Mother's Finest",
    1174: "Mother's Milk",
    1175: 'MTF',
    1176: 'Nepalese',
    1177: 'Nepali OG',
    1178: 'North American Indica',
    1179: 'Northern Lights #2',
    1180: 'Nuggetry OG',
    1181: 'Nuken',
    1182: 'NYC Sour Diesel',
    1183: 'OCD',
    1184: 'OG Eddy Lepp',
    1185: 'OG Kush Breath',
    1186: 'Orange Crush',
    1187: 'Orange Velvet',
    1188: 'Outlaw',
    1189: 'P-91',
    1190: 'Panama Red',
    1191: 'Pandoras Box',
    1192: 'Peanut Butter Breath',
    1193: 'Phishhead Kush',
    1194: 'Pineapple Haze',
    1195: 'Pineapple Jack',
    1196: 'Pineapple Punch',
    1197: 'Pineapple Purps',
    1198: 'Pineapple Super Silver Haze',
    1199: 'Pink Candy',
    1200: 'Pink Panties',
    1201: 'Platinum Granddaddy Purple',
    1202: 'Platinum Purple Kush',
    1203: 'Plum Crazy',
    1204: 'Plushberry',
    1205: 'Popcorn Kush',
    1206: 'Power Plant',
    1207: 'Purple Afghani',
    1208: 'Purple Berry',
    1209: 'Purple Buddha',
    1210: 'Purple Candy',
    1211: 'Purple Chemdawg',
    1212: 'Purple Cotton',
    1213: 'Purple Cream',
    1214: 'Purple Dragon',
    1215: 'Purple Elephant',
    1216: 'Purple Jolly Rancher',
    1217: 'Purple Mr. Nice',
    1218: 'Purple Nepal',
    1219: 'Purple Passion',
    1220: 'Purple Wreck',
    1221: 'Q3',
    1222: 'Qleaner',
    1223: 'Quin-N-Tonic',
    1224: 'Rare Darkness',
    1225: 'Raspberry Cough',
    1226: 'Red Cherry Berry',
    1227: 'Red Congolese',
    1228: 'Red Dragon',
    1229: 'Red Haze',
    1230: 'Red Headed Stranger',
    1231: 'Redwood Kush',
    1232: 'Rocklock',
    1233: 'Rockstar Master Kush',
    1234: 'Rollex OG Kush',
    1235: 'Root Beer Kush',
    1236: 'Royal Haze',
    1237: 'Royal Kush',
    1238: 'RudeBoi OG',
    1239: 'Runtz',
    1240: 'Russian Assassin',
    1241: 'Sage N Sour',
    1242: 'Santa Maria',
    1243: 'Schrom',
    1244: 'Seattle Cough',
    1245: 'Serious 6',
    1246: 'Shaman',
    1247: 'Sharksbreath',
    1248: 'Shipwreck',
    1249: 'Shishkaberry',
    1250: 'Shiva Skunk',
    1251: 'Silver Haze',
    1252: 'Silver Kush',
    1253: 'SinMint Cookies',
    1254: 'Skunk 47',
    1255: 'Skunk Dawg',
    1256: 'SleeStack',
    1257: 'Slymer',
    1258: 'Snoop Master Kush',
    1259: 'Sour Amnesia',
    1260: 'Sour Chocolate',
    1261: 'Sour Jack',
    1262: 'Sticky B',
    1263: 'Strawberry Ice',
    1264: 'Sugar Plum',
    1265: 'Sunshine',
    1266: 'Super Jack',
    1267: 'Tangerine Twist',
    1268: 'Temple Spice',
    1269: 'Thai',
    1270: 'Thai Haze',
    1271: 'Thai-Tanic',
    1272: 'The Black',
    1273: 'The Cough',
    1274: 'The Hog',
    1275: 'Thors Hammer',
    1276: 'Tigers Milk',
    1277: 'Triple Diesel',
    1278: 'Turbo Diesel',
    1279: 'Twista',
    1280: 'Twisted Citrus',
    1281: 'U2 Kush',
    1282: 'Ultimate Trainwreck',
    1283: 'UltraViolet OG',
    1284: 'UW',
    1285: 'Venice OG',
    1286: 'Very Berry Haze',
    1287: 'Viper',
    1288: 'Voodoo',
    1289: 'Vortex',
    1290: 'Waipio Hapa',
    1291: 'Watermelon',
    1292: 'Wet Dream',
    1293: 'White Buffalo',
    1294: 'White Haze',
    1295: 'White Kush',
    1296: 'White OG',
    1297: 'White Walker Kush',
    1298: 'Willie Nelson',
    1299: 'Willy Wonka',
    1300: 'Woody Kush',
    1301: 'X-Tra Chz',
    1302: 'Xanadu',
    1303: 'Y Griega',
    1304: 'Zookies',
    1305: 'Funky Dream',
    1306: 'Glue',
    1307: 'Hawaiian Delight',
    1308: 'Humble Pie',
  },
};
