import axios from 'axios';

// TODO: use URL for envs
export const baseURL = process.env.REACT_APP_LOCAL_SERVER
  ? 'http://localhost:8081/dev'
  : 'https://qaq1luhv50.execute-api.us-east-1.amazonaws.com/dev';

export const client = axios.create({
  baseURL,
});

export const setApiClientAuthHeader = (token) => {
  client.defaults.headers.common.Authorization = (token && `Bearer ${token}`) || null;
};
